@import "conf/footer_conf";

footer {
    background-color:  $footer_bg_color;
    @include footer_bg_image;
    @include footer_borders;
    div {
      margin-top: 0.5rem;
      font-size: 0.75rem;
      display: inline-block;
      font-weight: 400;
      line-height: 1.155rem;
      
      a {
        color: #fff;
  
        &:hover {
          color: $text_grey;
        }
  
        #heart {
          height: 16px;
          width: 16px;
  
          path {
            fill: $footer_heart_bg;
          }
        }
      }
    }
  }
  
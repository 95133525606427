/* UnlockRoute.scss */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #f2f2f2; /* Cambia el color de fondo según tus preferencias */
}

.card {
  width: 350px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #000; /* Cambia el color de fondo del card según tus preferencias */
  color: white;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Establece un ancho fijo para todos los botones */
.button-container button {
  width: 200px; /* Ajusta el ancho según tus preferencias */
  margin: 5px 0; /* Agrega un espacio entre los botones */
}

.card h4 {
  margin-bottom: 20px;
}

.card p {
  margin-bottom: 10px;
}

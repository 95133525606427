/* Estilo para el contenedor del Navbar */
.container-fluid {
  display: flex;
  align-items: center;
}



/* Estilo para el nombre de la aplicación */
.dapp-name {
  font-size: 1.5rem; /* Tamaño de fuente personalizable */
}

/* Estilo para el balance */
.row {
  margin-left: 20px; /* Espacio entre el nombre de la aplicación y el balance */
  font-size: 1.2rem; /* Tamaño de fuente personalizable */
}

/* Estilo para el botón de logout */
.btn-link {
  background-color: #dc3545 !important  ;
}



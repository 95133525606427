@import "general_conf.scss";
//Fondos
$footer_bg_color: $general_bg_color;
$footer_color: $general_color;
@mixin footer_bg_image {
    background-image: none;
}

//bordes
@mixin footer_borders {
    border: none;
}

//Otros elementos del pie:
$footer_heart_bg: #c00;
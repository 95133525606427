//Fondos
$general_bg_color: transparent;
$navbar_bg_color: transparent;
$text_grey: #9FA3A7;
$general_color: rgb(33, 37, 41);
@mixin general_bg_image {
    background-image: none;
}

//bordes
@mixin general_borders {
    border: none;
}


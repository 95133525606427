@import "../../assets/sass/erpydevs_theme/conf/header_conf.scss";


/* Estilo para el contenedor principal */
.primary_container {
  /* border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  background-color: #000 ;
  */

  width: 98%;
  background-color: #000;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
}

/* Estilo para el texto "Wallet Owner" */
.mb-3 {
  /*
  font-size: 1.2rem;
  font-weight: bold;
  */
}

/* Estilo para la dirección de la billetera */
span {
  font-size: 0.9rem;
  color: #fff; /* Color de texto gris */
}

/* Estilo para el contenedor de carga de archivos */
.d-flex {
  margin-top: 20px;
}

/* Estilo para el botón "Submit" */
.btn-primary {
  background-color: #007bff; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  border: none; /* Eliminar borde */
  padding: 10px 20px; /* Espaciado interno */
  border-radius: 5px; /* Borde redondeado */
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Efecto de transición */
}

/* Cambia el color del botón al pasar el mouse sobre él */
.btn-primary:hover {
  background-color: #0056b3; /* Color de fondo al pasar el mouse */
}

.text_grey{
color: $text_gray;

}

/* Para dispositivos de escritorio */
.custom-dropdown {
  padding-left: 95%;
}

.custom-dropdown button{
  border-radius: 0px !important;
}

/* Para dispositivos móviles (ancho máximo de 767px, puedes ajustar este valor) */
@media (max-width: 767px) {
  .custom-dropdown {
    padding-left: 80%;
  }
}
@import "header";
@import "main";
@import "footer";
@import "conf/font_conf.scss";
@import "conf/general_conf.scss";
html {
    min-height: 100%;
    font-size: 14px;
    body {
        margin: 0;
        padding: 0;
        @include main_font;
        color: $general_color;
        @include general_bg_image;
        @include general_borders;
    }
}

code {
    @include alt_font_1;
}

//Elementos básicos
.wrapper {
    min-height: 100vh;
}

.dapp-icon {
    padding: 5px;
    border-radius: 50%;
    background-color: $light;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    &.icon-medium {
        width: 80px;
        height: 80px;
    }
}


/* Loader, Transaction success/Fail
-------------------------------------------------- */

.page-state {
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .bg-blue .lds-ellipsis div {
        background: rgba(255, 255, 255, 0.4);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(19px, 0);
        }
    }
}


/* Actions
-------------------------------------------------- */

.not-allwed {
    cursor: not-allowed;
}


/* Botones
------------------------------------------------- */

button, button.btn {
    background-color: #0056b3 ;
    border: 1px solid white;
    border-radius: 0.25rem;
    color: white;
}

/* Navegación
------------------------------------------------- */
.nav-item {
    margin-left: 10px;
    button{
        white-space: nowrap;
    }
}

@media (max-width: 575.98px) { 
    .upload-area{
        flex-direction: column !important;
        gap: 1rem;
    }
 }

@import "general_conf.scss";
//Fondos
$main_bg_color: $general_bg_color;
$main_color: $general_color;
@mixin main_bg_image {
    background-image: none;
}

//bordes
@mixin main_borders {
    border:none;
  }
/* ToggleForm.scss */
.toggle-form {
    label {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-weight: bold;
        margin-right: 20px; /* Agrega espacio entre el span y el Switch */
        justify-content: space-between;
    }

    .react-switch-bg {
        background-color: #f0f0f0; /* Color de fondo del switch inactivo */
    }

    .react-switch-handle {
        background-color: #fff; /* Color del mango del switch */
        border: 1px solid #ccc;
    }

    .react-switch-bg > div {
        background-color: #07c160; /* Color de fondo del switch activo */
    }

    .switch-label {
        margin-left: 10px;
    }
   
}

//Vamos a definir las variables de estilo para que sea más fácil modificar los elementos
@import "conf/header_conf";
body .navbar {
    @include header_bg_image;
    .elrond-logo {
        width: 85px;
        //height: 25px;
    }
    .dapp-name {
        line-height: 4;
        border-left: 1px solid $gray-400;
        padding-left: 15px;
        margin-left: 15px;
    }
}

body {
    background-color: $header_bg_color;
}
.navbar{
    background-color: $navbar_bg_color;
}
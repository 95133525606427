@import "conf/main_conf";
main{
    background-color:  $main_bg_color;
    @include main_bg_image;
    @include main_borders;
}

.main-xsd{
    background-image: url(https://i.postimg.cc/Mw3nfphH/bg-smallx.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
/* UploadStyles.scss */

main{
    margin-top: 20px;
    height: fit-content;
}

.primary_container {
    background-color: transparent !important;
}

.wrapper {
  background-color: black;
}

.btn-deploy {
  border: solid aqua !important;
  color: white !important;
  background-color: transparent !important;
  font-size: 24px;
}

.upload-container {
  align-items: center;
  background-color: #121212cc;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  max-height: 500px;
  min-height: 250px;
  align-content: center;
  justify-content: center;

  .selected-file {
    text-align: center;
    margin-bottom: 20px;

    .icon {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
  }

  .input-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .file-upload-container {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &.active {
      border-color: #007bff;
      background-color: #f0f0f0;
    }

    p {
      margin-top: 10px;
    }
  }
}
